import React,{useState, useEffect} from 'react'
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Layouts/Main/Home';
import NoPage from './Layouts/Main/NoPage';
import CompanyAccordion from './Components/Company/CompanyAccordion';
import Header from './Layouts/Header/Header';
import HeaderLogin from './Layouts/Header/HeaderLogin';

import HeaderAfterLogin from './Layouts/Header/HeaderAfterLogin';
import Footer from './Layouts/Footer/Footer';
import ProjectDetails from './Components/Allprojects/ProjectDetails';
import JoinUs from './Components/Account/JoinUs';
import Cookies from 'universal-cookie';
import ResetPassword from './Components/Account/ResetPassword';
import ChangePassword from './Components/Account/ChangePassword';
import UserProfileMainComponent from './Components/UserProfile/UserProfileMainComponent';
import ServiceRequest from './Components/ServiceRequest/ServiceRequest';
import ViewServiceRequest from './Components/ServiceRequest/ViewServiceRequest';
import EditCompany from './Components/Company/EditCompany';
import ActivityLog from './Components/Activity/ActivityLog';





function App() {
const cookies = new Cookies();
return (
      
<div className="container-fluid">
  
   <BrowserRouter>
      <Routes>
        <Route path="/" element={ cookies.get("accessToken") == null ? <HeaderLogin /> : <Header/> }>
          <Route index element={<Home />} />
          <Route path="projects" element={<CompanyAccordion />} />
          <Route path="/joinUs" element={<JoinUs/>}/>
          <Route path="/projects/:companyId/:projectId" element={<ProjectDetails/>}/>   
          <Route path="/projects/:projectId" element={<ProjectDetails/>}/>
          <Route path="/resetPassword" element={<ResetPassword />} />
          {/* <Route path="/userChangePassword" element={<HeaderAfterLogin redirectURL={"https://apiaccess-st.nets.eu/changePassword"} />} /> */}
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/developer/*" element={<HeaderAfterLogin />} />
          <Route path="/userProfile" element={<UserProfileMainComponent />} />
          <Route path="/serviceRequest" element={<ServiceRequest />} />
          <Route path="/serviceRequest/:company/:confirmedRepId/:requestType/:requestSubType" element={<ServiceRequest/>}/>
          <Route path="/serviceRequest/:company/:confirmedRepId/:requestType/:requestSubType/:usrExist" element={<ServiceRequest/>}/>
          <Route path="/viewserviceRequests" element={<ViewServiceRequest />} />
          <Route path="/activityLog" element={<ActivityLog />} />
          <Route path="/editCompany/:companyId" element={<EditCompany />} />
         
        </Route>
      </Routes>
    </BrowserRouter>
      <Footer/>
      
</div>
 
   );
}

export default App;